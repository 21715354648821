import React, { useState, useLayoutEffect } from "react";
import ReactMarkdown from "react-markdown";

import Info from "@templates/Info";
import Button from "@atoms/Button";
import ContentBox from "@atoms/ContentBox";
import Icon from "@atoms/Icon";
import Text from "@atoms/Text";

import { cookies } from "@content/cookies";
import PaperPlaneSvg from "@images/icons/paper-plane.svg";
import AtSvg from "@images/icons/at.svg";
import XSvg from "@images/icons/x-social.svg";

const CookiesPage = () => {
  const [startPage, setStartPage] = useState(false);

  useLayoutEffect(() => {
    setTimeout(() => setStartPage(true), 500);
  }, [setStartPage]);

  return (
    <Info active={startPage}>
      <ContentBox>
        <h2>
          <Icon src={PaperPlaneSvg} /> Contact
        </h2>
      </ContentBox>
      <ContentBox>
        <Text>
          <h3>Want to get in touch? We'd love to hear from you.</h3>
          <br />
          <p>
            <a href="mailto:contact@fplrank.live">
              <Button size="sm" color="primary">
                <Icon src={AtSvg} /> <p>contact@fplrank.live</p>
              </Button>
            </a>
          </p>
          <p>
            <a href="https://twitter.com/FPLrank_live">
              <Button size="sm" color="primary">
                <Icon src={XSvg} /> <p>follow us on X</p>
              </Button>
            </a>
          </p>
        </Text>
      </ContentBox>
    </Info>
  );
};

export default CookiesPage;
